/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, PriceWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Ceník"}>
        <SiteHeader />

        <Column className="pb--40 pt--80" name={"cenik"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1 pb--30 pt--30" anim={"2"} animS={"5"} style={{"maxWidth":1080}} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":900}}>
              
              <Title className="title-box" style={{"maxWidth":700}} content={"Ceník"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text. "}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"cenik-2"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Začněte psát text, tento prostor je určený pro váš libovolný text."}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"179,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Začněte psát text, tento prostor je určený pro váš libovolný text."}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"179,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Začněte psát text, tento prostor je určený pro váš libovolný text."}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"179,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Začněte psát text, tento prostor je určený pro váš libovolný text."}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"179,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"#f4f4f4"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box" content={"Začněte psát text, tento prostor je určený pro váš libovolný text."}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"179,— Kč"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--30" name={"kontakt"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1 pb--30 pt--30" anim={"2"} animS={"5"} style={{"maxWidth":1080}} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":900}}>
              
              <Title className="title-box" style={{"maxWidth":700}} content={"Holandská 854/1, Brno<br>+420 797 811 2X0<br>info@vase-stranky.com"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":""}} content={"<a href=\"https://saywebpage.com\">Tvorba web stránek</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}